import React, { useEffect, useState } from "react"

import Title from "../component/common/title"
import MemberTab from "../component/member/member-tab"
import AccountBasic from "../component/member/account-basic"
import AccountInterest from "../component/member/account-interest"
// import AccountBtn from "../component/member/account-btn"
import PopupHint from "../component/popup/popup-hint-template-1"

import iconHead from "../img/icon_head.svg"
import apiService from "../service/apiService"
import RTUtils from "../lib/rt-utils"

const PageTitle = { pic: iconHead, name: "會員專區" }

function MemberAccount() {
  const [user, setUser] = useState()
  const [errorMessage, setErrorMessage] = useState("")
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await apiService.apiGetUserInfo()
        setUser(user)
        // console.log(user)
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [])

  const onChange = (u) => {
    // console.log('onChange', u)
    setUser(u)
  }

  const validateInputs = () => {
    if (!RTUtils.validateFullName(user.fullName)) {
      showErrorMessage("姓名長度過短")
      return false
    }
    // if (!RTUtils.validateMobilePhoneTW(user.phoneNumber)) {
    if (user.phoneNumber.length === 0) {
      showErrorMessage("手機號碼格式有誤")
      return false
    }
    if (user.gender.length === 0) {
      showErrorMessage("請選擇性別")
      return false
    }
    if (user.birthYear.length === 0) {
      showErrorMessage("請選擇出生年")
      return false
    }
    if (user.birthMonth.length === 0) {
      showErrorMessage("請選擇出生月")
      return false
    }
    if (user.address.length === 0) {
      showErrorMessage("請選擇居住地")
      return false
    }
    if (user.family.length === 0) {
      showErrorMessage("請選擇家庭狀況")
      return false
    }
    if (user.interests.length === 0) {
      showErrorMessage("請勾選您有興趣的主題")
      return false
    }
    return true
  }

  const showErrorMessage = (msg) => {
    setErrorMessage(msg)
    setTimeout(() => {
      setErrorMessage("")
    }, 2000)
  }

  const handleSubmit = async () => {
    if (!validateInputs) return
    try {
      // const resp =
      await apiService.apiUpdateUser(user)
      // console.log('update response', resp)
      setShowPopup(true)
    } catch (e) {
      console.log("update error", e)
      setErrorMessage(`更新失敗：${e.response.data.error.message}`)
    }
  }

  return (
    <div className="container">
      <PopupHint
        trigger={showPopup}
        setTrigger={setShowPopup}
        title="更新完成"
        message="您的個人資訊已經更新。"
        onOk={() => setShowPopup(false)}
      />

      <div className="d-flex align-items-center justify-content-between title_content">
        <Title data={PageTitle} />
        <div className="member_tab-box d-md-block d-none">
          <MemberTab />
        </div>
      </div>
      <div className="d-flex flex-wrap content_main">
        <div className="content_filter d-md-block d-none"></div>
        <div className="content_list">
          <div className="member_tab-box d-md-none d-block">
            <MemberTab />
          </div>
          <AccountBasic user={user} onChange={onChange} />
          <AccountInterest user={user} onChange={onChange} />
          <div>
            {/* <div className='account_setting term'>
                <div className='check d-flex align-items-center justify-content-center' onClick={handleActive}>
                    <div className="check_box"></div>
                        <span>我同意XXXX的<Link  to="/term">會員服務條款。</Link></span>
                    </div>
            </div> */}
            <div className="account_btn">
              <div className="d-flex align-items-center justify-content-center">
                {/* <button className='btn-gray'>重新填寫</button> */}
                <button className="btn-common" onClick={handleSubmit}>
                  確認送出
                </button>
              </div>
              <p>{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberAccount
