import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiService from "../../service/apiService";

function SeoProvider({ children, defaultSeoData }) {
  const [seoData, setSeoData] = useState(defaultSeoData);
  const location = useLocation();

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        // Ensure URL has a leading slash
        const urlPath = location.pathname.startsWith('/') ? location.pathname : `/${location.pathname}`;
        
        const response = await apiService.getSeo({ url: urlPath });
        
        // Since the response is a direct object, use it directly
        if (response && typeof response === 'object' && response !== null) {
          const apiSeoData = response;
          
          const newSeoData = {
            // Basic SEO fields
            title: apiSeoData.title || defaultSeoData.title,
            description: apiSeoData.description || defaultSeoData.description,
            keywords: apiSeoData.keywords || defaultSeoData.keywords,
            imageUrl: apiSeoData.ogImage || apiSeoData.imageUrl || defaultSeoData.imageUrl,
            
            // Open Graph specific fields
            ogTitle: apiSeoData.ogTitle || apiSeoData.title,
            ogDescription: apiSeoData.ogDescription || apiSeoData.description,
            ogImage: apiSeoData.ogImage || apiSeoData.imageUrl,
            ogType: apiSeoData.ogType || defaultSeoData.ogType,
            ogSiteName: apiSeoData.ogSiteName || defaultSeoData.ogSiteName,
            ogLocale: apiSeoData.ogLocale || defaultSeoData.ogLocale,
            
            // Twitter Card specific fields
            twitterCard: apiSeoData.twitterCard || defaultSeoData.twitterCard,
            twitterTitle: apiSeoData.twitterTitle || apiSeoData.ogTitle || apiSeoData.title,
            twitterDescription: apiSeoData.twitterDescription || apiSeoData.ogDescription || apiSeoData.description,
            twitterImage: apiSeoData.twitterImage || apiSeoData.ogImage || apiSeoData.imageUrl,
            twitterSite: apiSeoData.twitterSite || apiSeoData.ogSiteName
          };
          
          setSeoData(newSeoData);
        }
      } catch (error) {
        // Keep using default SEO data if request fails
      }
    };

    // Only fetch SEO data if we have a path
    if (location.pathname) {
      fetchSeoData();
    }
  }, [location.pathname, defaultSeoData]);

  // Return children with seoData prop
  return children(seoData);
}

export default SeoProvider;