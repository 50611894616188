import React, { useEffect, useState } from 'react';

import HomeBanner from '../component/home/home_banner';
import LessonList from '../component/list/lessonList';
import HomeOtherList from '../component/home/home_otherList';
import PopupLoginTemplate from '../component/popup/popup-login-template';
import ForgetPassword2 from '../component/popup/forget-password-2';

import IconLesson from '../img/icon_lesson.svg';
import IconWatch from '../img/icon_watch_color.svg';
import IconListen from '../img/icon_listen_color.svg';
import IconRead from '../img/icon_read_color.svg';

import apiService from '../service/apiService';
import useUserStatus from '../hooks/useUserStatus';
import { useSearchParams } from 'react-router-dom';
import { Default } from 'react-awesome-spinners';

const TitleLesson = { pic: IconLesson, title: '最新課程', link: '/lesson' };
const TitleWatch = { pic: IconWatch, title: '看', link: '/watch' };
const TitleListen = { pic: IconListen, title: '聽', link: '/listen' };
const TitleRead = { pic: IconRead, title: '讀', link: '/read' };

function HomeNew() {
    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');
    const token = searchParams.get('token');

    const [loading, setLoading] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [banners, setBanners] = useState([]);
    const [top3, setTop3] = useState({ lessons: [], watch: [], listen: [], read: [] });
    const isLoggedIn = useUserStatus('');

    useEffect(() => {
        // log package version
        var version = require('../../package.json').version;
        console.log('v', version);
        setLoading(true);
        const fetchData = async () => {
            const banners = await apiService.apiHomeBanners({ location: 'TOP' });
            setBanners(banners);
            const top3 = await apiService.apiTop3();
            setTop3(top3);
            setLoading(false);
            if (action && token) {
                if (action === 'reset-password') {
                    setShowChangePassword(true);
                }
            }
        };
        fetchData();
        console.log('action', action);
        console.log('token', token);
    }, [action, token]);

    return (
        <>
            {loading && (
                <div className="loader">
                    <Default color="black" />
                    <p>讀取中</p>
                </div>
            )}
            {!loading && (
                <div>
                    <HomeBanner banners={banners.filter(b => b.location === 'TOP')} />
                    <div className="container">
                        <div className="home_item">
                            <LessonList title={TitleLesson} contentList={top3.lessons.filter(l => l.productType == 3)} detailLink="/lesson-content" requireLogin={false} />
                        </div>
                        <div className="home_item">
                            <HomeOtherList title={TitleWatch} contentList={top3.watch} detailLink="/watch-inner" requireLogin={!isLoggedIn} />
                        </div>
                        <div className="home_item">
                            <HomeOtherList title={TitleListen} contentList={top3.listen} detailLink="/listen-inner" requireLogin={!isLoggedIn} />
                        </div>
                        <div className="home_item">
                            <HomeOtherList title={TitleRead} contentList={top3.read} detailLink="/read-inner" requireLogin={false} />
                        </div>
                    </div>
                    <PopupLoginTemplate trigger={showChangePassword} setTrigger={setShowChangePassword}>
                        <ForgetPassword2 token={token} onOk={() => setShowChangePassword(false)} />
                    </PopupLoginTemplate>
                </div>
            )}
        </>
    );
}

export default HomeNew;
