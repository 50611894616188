import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

// import InnerSwiper from "../component/content/inner-swiper"
import ArticleContent from "../component/content/article-content"
import ArticlePagination from "../component/content/article-pagination"
import InnerIcon from "../component/content/inner-icon"
import apiService from "../service/apiService"
import constants from "../lib/constants"

// import HeaderTags from "../component/header/header-tags"
import { Default } from "react-awesome-spinners"

function ReadInner() {
  const navigate = useNavigate()
  const { id } = useParams()
  // const [banners, setBanners] = useState([])
  const [product, setProduct] = useState({})
  const [prevNext, setPrevNext] = useState({
    hasPrev: false,
    hasNext: false,
    prevId: 0,
    nextId: 0,
  })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // const banners = await apiService.apiHomeBanners()
        // setBanners(banners)
        const product = await apiService.apiProduct({ id: id })
        if (product.productType === constants.productType.read) {
          setProduct(product)
        } else {
          navigate("/")
        }
        const prevNext = await apiService.apiPrevNext({ id: id })
        // console.log(prevNext)
        setPrevNext(prevNext)
      } catch (e) {
        console.log('----')
        console.log(e)
        if (e.status === 403) {
          // unauthorized or no product for this id
          navigate("/member-record?redeem=true")
        } else if (e.response.status === 404) {
          navigate("/")
        } else {
          // display error
          console.log(e.response)
          navigate("/")
        }
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, navigate])
  return (
    <>
    {loading && <div className="loader"><Default color="black"/><p>讀取中</p></div>}
    {!loading && <div>
      {/* <HeaderTags
        title={product.name}
        description={product.description}
        imageUrl={`${constants.imageBaseUrl}/product/${product.imageMobile}`}
      /> */}
      {/* <InnerSwiper banners={banners.filter(b => b.location === 'READ')} /> */}
      <InnerIcon backLink={"/read"} product={product} />
      <ArticleContent product={product} />
      <ArticlePagination prevNext={prevNext} />
    </div>}
    </>
  )
}

export default ReadInner
