import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderTags from "../component/header/header-tags";
import Header from "./Header";
import RouteView from "./RouteView";
import Footer from "./Footer";
import SeoProvider from "../component/seo/SeoProvider";

// Default SEO data to use when API hasn't responded yet or if it fails
const defaultSeoData = {
  title: "讀聽格林｜看線上課程｜聽故事有聲書｜讀繪本解析｜會員專屬影音",
  description: "格林文化會員專屬影音平台！小孩到成人都適合的線上課程及有聲書、總編輯郝廣才專欄、熱門親子教養話題、推薦繪本導讀與解析，讓閱讀更豐富、更有深度！",
  keywords: "線上課程、兒童線上課、繪本導讀、郝廣才、繪本推薦、中文有聲書、故事有聲書、親子共讀、閱讀",
  imageUrl: "https://d2cgzwr7140f0n.cloudfront.net/images/banner/%E9%A6%96%E9%A0%81BN_03.jpg",
  ogSiteName: "讀聽格林",
  ogLocale: "zh_TW",
  ogType: "website",
  twitterCard: "summary_large_image"
};

function App() {
    return (
        <HelmetProvider>
            <Router>
                <SeoProvider defaultSeoData={defaultSeoData}>
                    {(seoData) => (
                        <>
                            <HeaderTags seoData={seoData} />
                            <Header />
                            <main>
                                <RouteView />
                            </main>
                            <Footer />
                        </>
                    )}
                </SeoProvider>
            </Router>
        </HelmetProvider>
    );
}

export default App;