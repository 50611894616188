import { Helmet } from "react-helmet-async";

const HeaderTags = ({ seoData }) => {
  // Destructure with fallback values
  const {
    title = "讀聽格林｜看線上課程｜聽故事有聲書｜讀繪本解析｜會員專屬影音",
    description = "格林文化會員專屬影音平台！小孩到成人都適合的線上課程及有聲書、總編輯郝廣才專欄、熱門親子教養話題、推薦繪本導讀與解析，讓閱讀更豐富、更有深度！",
    keywords = "線上課程、兒童線上課、繪本導讀、郝廣才、繪本推薦、中文有聲書、故事有聲書、親子共讀、閱讀",
    imageUrl = "https://d2cgzwr7140f0n.cloudfront.net/images/banner/%E9%A6%96%E9%A0%81BN_03.jpg",
    ogTitle,
    ogDescription,
    ogImage,
    ogType = "website",
    ogSiteName = "讀聽格林",
    ogLocale = "zh_TW",
    twitterCard = "summary_large_image",
    twitterTitle,
    twitterDescription,
    twitterImage,
    twitterSite
  } = seoData || {};

  // Use title as fallback for og:title and twitter:title if they're not provided
  const finalOgTitle = ogTitle || title;
  const finalOgDescription = ogDescription || description;
  const finalOgImage = ogImage || imageUrl;
  const finalTwitterTitle = twitterTitle || finalOgTitle;
  const finalTwitterDescription = twitterDescription || finalOgDescription;
  const finalTwitterImage = twitterImage || finalOgImage;
  const finalTwitterSite = twitterSite || ogSiteName;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" key="description" content={description} />
      <meta name="title" key="title" content={title} />
      <meta name="keywords" key="keywords" content={keywords} />
      
      {/* Open Graph tags */}
      <meta property="og:title" key="og:title" content={finalOgTitle} />
      <meta property="og:locale" key="og:locale" content={ogLocale} />
      <meta charSet="utf-8" />
      <meta property="og:type" key="og:type" content={ogType} />
      <meta property="og:description" key="og:description" content={finalOgDescription} />
      <meta property="og:image" key="og:image" content={finalOgImage} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content={ogSiteName} />
      
      {/* Twitter Card tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={finalTwitterTitle} />
      <meta name="twitter:description" content={finalTwitterDescription} />
      <meta name="twitter:image" content={finalTwitterImage} />
      <meta name="twitter:site" content={finalTwitterSite} />
      
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default HeaderTags;