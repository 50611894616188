import React, { useEffect, useRef, useState } from 'react';
// import constants from '../../lib/constants';
import apiService from '../../service/apiService';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

const ClassroomVideo = ({ product, productId, videoUrl, onPlayComplete }) => {
    const videoRef = useRef(null);
    const [player, setPlayer] = useState();

    const trackUsage = async progress => {
        // console.log('in trackUsage', productId, progress);
        if (productId === null || productId === undefined) return;
        await apiService.apiPostHistory({ productId: productId, progress: progress.playedSeconds });
    };

    useEffect(() => {
        let trackUsageIntervalId;
        const loadHistory = async id => {
            const resp = await apiService.apiGetHistory({ productId: id });
            // console.log('in loadHistory', resp.content[0]?.progress);
            if (player.current && resp.content[0]?.progress) {
                console.log(`progress: ${resp.content[0]?.progress} / duration: ${product.contentLength}`);
                if (parseInt(resp.content[0]?.progress) < parseInt(product.contentLength * 0.9)) {
                    player.current.currentTime = resp.content[0].progress;
                }
                player.current.play();
            }
        };
        if (product.id && player) {
            loadHistory();
        }
        // console.log('in productId / videoUrl', productId, videoUrl);
        // if (videoUrl?.includes('mp4') && videoRef.current) {
        //     videoRef.current.load();
        //     if (productId && videoRef.current) {
        //         loadHistory()
        //     }

        //     videoRef.current.onplay = () => {
        //         console.log('track videoUrl', videoUrl);
        //         trackUsageIntervalId = setInterval(() => {
        //             // console.log('ontimeupdate event triggered');
        //             trackUsage(videoRef.current.currentTime);
        //         }, 5000);
        //     };

        //     videoRef.current.onpause = videoRef.current.onended = () => {
        //         trackUsage(videoRef.current.currentTime);
        //         clearInterval(trackUsageIntervalId);
        //     };
        // }

        return () => clearInterval(trackUsageIntervalId); // Clear interval when component unmounts
    }, [product, productId, videoUrl, player]);

    useEffect(() => {
        if (Hls.isSupported() && videoUrl.includes('.m3u8')) {
            const hls = new Hls({
                startLevel: -1 // Start with the lowest level
            });
            hls.loadSource(videoUrl);
            hls.attachMedia(videoRef.current.getInternalPlayer());
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                videoRef.current.getInternalPlayer().play();
            });
        }
    }, [videoUrl]);

    return (
        <div className="classroom_video" onContextMenu={e => e.preventDefault()}>
            {/* {videoUrl?.includes('youtube') &&
                <iframe width="560" height="315" src={videoUrl} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            }
            {videoUrl?.includes('mp4') &&
                <video ref={videoRef} controls preload="auto" width="100%" height="auto">
                    <source src={videoUrl} type="video/mp4" />
                </video>
            } */}
            <ReactPlayer
                url={videoUrl}
                controls
                width={'100%'}
                height={'100%'}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0'
                }}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }}
                ref={ele => {
                    setPlayer(ele);
                    videoRef.current = ele;
                }}
                progressInterval={5000}
                onProgress={trackUsage}
                playing={true}
                onEnded={e => {
                    console.log('onEnded', e);
                    onPlayComplete();
                }}
            />
        </div>
    );
};

export default ClassroomVideo;
