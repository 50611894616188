import React from 'react';
import constants from '../../lib/constants';

function LessonTabIntro({ intro }) {
    return (
        <div className="lesson_tab_intro">
            {intro?.map((item, index) => {
                return item.link ? (
                    <a href={item.link} key={index}>
                        <img src={`${constants.imageBaseUrl}/${item.image}`} alt={item.title} />
                    </a>
                ) : (
                    <img src={`${constants.imageBaseUrl}/${item.image}`} alt={item.title} key={index} />
                );
            })}
        </div>
    )
}

export default LessonTabIntro;